@use './colors.scss' as*;

@media (max-width: 768px){
.actu{
    .all-card{
        display: flex;
        
    }

}
}