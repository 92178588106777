@use './colors.scss' as*;

@media (max-width: 768px){

    .screenshot-carousel {
        margin: auto;
        padding-bottom: 1em;
        width: 95%; // Largeur maximale du carrousel
    }
      
    .screenshot-item {
        overflow: hidden; // Pour éviter que l'image ne dépasse
        text-align: center; // Centrer le texte
        margin: auto;
    }
      
    .screenshot-image {
        
        width: 100%; // Prendre toute la largeur de la carte
        margin: auto;
        img{
            width: 100%;
        }
    }
      
    .screenshot-info {
        color: $Colortextwithe;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
       
    }
      
    .screenshot-info h3 {
        font-size: 1em; // Taille du titre
        width: 100%;
        margin-top:0 ;
        margin-bottom: 0;
    }
      
    .screenshot-info p {
        margin: 5px 0;
        width: 100%;
        margin: auto; 
        margin-bottom: 0;
        margin-top:0 ;
    }
    
    .screenshot-preview-gallery {
        
        display: flex;
        justify-content: center; // Centrer la galerie d'aperçus
        margin-top: 15px; // Espacement au-dessus de la galerie
      
    }
      
    .screenshot-preview {
        
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 30%; // Largeur de chaque aperçu
        margin: 0 5px; // Espacement entre les aperçus
        border: 2px solid transparent; // Bordure par défaut
        border-radius: 4px; // Coins arrondis
        cursor: pointer; // Curseur de main au survol
        transition: border-color 0.3s; // Transition douce pour le changement de bordure
        margin-top: 5em;
    }
      
    .screenshot-preview.active {
        border-color: $Colortextred; // Bordure rouge pour l'image active
    }
    
    .custom-arrow {
        display: none;
        font-size: 4em; // Taille de la flèche
        color: $Colortextred; // Couleur de la flèche
        cursor: pointer; // Curseur de main au survol
        z-index: 1; // Assurez-vous que les flèches soient au-dessus des autres éléments
        position: absolute; // Positionner les flèches de manière absolue
        top: 50%; // Centrer verticalement
        transform: translateY(-50%); // Ajuster pour centrer exactement
    }
      
    .prev {
        left: -40px; // Positionnement à gauche
    }
      
    .next {
        right: -40px; // Positionnement à droite
    }
    
    
    
    
}

@media (min-width: 768px) {
    .screenshot-carousel {
        margin: auto;
        padding-bottom: 1em;
        width: 60%; // Largeur maximale du carrousel
    }
      
    .screenshot-item {
        overflow: hidden; // Pour éviter que l'image ne dépasse
        text-align: center; // Centrer le texte
        margin: auto;
    }
      
    .screenshot-image {
        width: 100%; // Prendre toute la largeur de la carte
        margin: auto;
        margin-top: 3em;
        img{
            width: 100%;
        }
    }
      
    .screenshot-info {
        color: $Colortextwithe;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
       
    }
      
    .screenshot-info h3 {
        font-size: 1em; // Taille du titre
        width: 100%;
        margin-top:0 ;
        margin-bottom: 0;
    }
      
    .screenshot-info p {
        margin: 5px 0;
        width: 100%;
        margin: auto; 
        margin-bottom: 0;
        margin-top:0 ;
    }
    
    .screenshot-preview-gallery {
        display: flex;
        justify-content: center; // Centrer la galerie d'aperçus
        margin-top: 15px; // Espacement au-dessus de la galerie
      
    }
      
    .screenshot-preview {
        display: none;
        width: 15%; // Largeur de chaque aperçu
        margin: 0 5px; // Espacement entre les aperçus
        border: 2px solid transparent; // Bordure par défaut
        border-radius: 4px; // Coins arrondis
        cursor: pointer; // Curseur de main au survol
        transition: border-color 0.3s; // Transition douce pour le changement de bordure
    }
      
    .screenshot-preview.active {
        border-color: $Colortextred; // Bordure rouge pour l'image active
    }
    
    .custom-arrow {
        
        font-size: 4em; // Taille de la flèche
        color: $Colortextred; // Couleur de la flèche
        cursor: pointer; // Curseur de main au survol
        z-index: 1; // Assurez-vous que les flèches soient au-dessus des autres éléments
        position: absolute; // Positionner les flèches de manière absolue
        top: 50%; // Centrer verticalement
        transform: translateY(-50%); // Ajuster pour centrer exactement
    }
      
    .prev {
        left: -40px; // Positionnement à gauche
    }
      
    .next {
        right: -40px; // Positionnement à droite
    }
    
    
    
}

