@use '../colors.scss' as*;

@media (max-width: 768px){
  /* Style de base pour AdminBanqueKamas */
.admin-banque-kamas {
  width: 70%;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: transparent;


  .total-kamas {

    font-weight: bold;
    font-size: 36px;
    color: $Colortextwithe; /* couleur verte */
  }

  .total-image {
    width: 20%;
    margin-left: 10px;
    
  }

  .columns {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .column {
      width: 48%;
      text-align: center;
    
      padding: 15px;
      
      background-color: transparent;
      

      h3 {
        color: $Colortextviolet;
        margin-bottom: 10px;
      }

      p {
        
        color: $Colortextwithe; /* couleur verte pour les montants */
      }
    }
  }

  .progress-bar {
    background-color: #f3f3f3;
    width: 100%;
    height: 20px;
    border-radius: 5px;
    margin-top: 20px;
    position: relative;
    margin-bottom: 3em;
    p{
      color:$Colortextviolet;
     
    }

    .progress-bar-fill {
      background-color: #afa517; /* couleur verte */
      height: 100%;
      border-radius: 5px;
      transition: width 0.3s ease-in-out;
      
    }
    
  }

  .progress-text {
    text-align: center;
 

    
  }
}

.admin-banque-kamas h2,
.admin-banque-kamas h3 {
  color: $Colortextviolet;

  
}


.formbanque {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
  
  
  
}


form label {
  font-weight: bold;
  color: $Colortextviolet;
  
}

form input[type="number"],
form input[type="text"],
form input[type="file"],
form select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
  margin-top: 1em;
  
}

form button[type="submit"] {
  padding: 10px;
  background-color: $Colortextred;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 50%;
  margin: auto;
 
}

form button[type="submit"]:hover {
  background-color: #00796b;
}

.transactions-list {
  list-style: none;
  padding: 0;
}

.transaction-item {
  background-color:transparent;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  width: 80%;
  margin: auto;
}

.transaction-item p {
  margin: 5px 0;
  color: $Colortextwithe;
  text-align: left;
  
}

.transaction-item strong {
  color: $Colortextviolet;
  
}

.transaction-item img {
  margin-top: 10px;
  border-radius: 4px;
  max-width: 100px;
}
.transactions-columns {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .transactions-column {
    width: 48%;  /* Pour que chaque colonne occupe environ la moitié de la largeur */
    background-color: transparent;

    h4 {
      color: $Colortextviolet;
      margin-bottom: 10px;
    }

    .transactions-list {
      list-style: none;
      padding: 0;
    }

    .transaction-item {
      background-color: transparent;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      padding: 10px;
      margin-bottom: 15px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    }
  }
}

}
@media (min-width: 768px){
  /* Style de base pour AdminBanqueKamas */
.admin-banque-kamas {
  width: 70%;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: transparent;


  .total-kamas {

    font-weight: bold;
    font-size: 36px;
    color: $Colortextwithe; /* couleur verte */
  }

  .total-image {
    width: 5%;
    margin-left: 10px;
    
  }

  .columns {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .column {
      width: 48%;
      text-align: center;
    
      padding: 15px;
      
      background-color: transparent;
      

      h3 {
        color: $Colortextviolet;
        margin-bottom: 10px;
      }

      p {
        font-size: 1.2em;
        color: $Colortextwithe; /* couleur verte pour les montants */
      }
    }
  }

  .progress-bar {
    background-color: #f3f3f3;
    width: 100%;
    height: 20px;
    border-radius: 5px;
    margin-top: 20px;
    position: relative;
    margin-bottom: 3em;
    p{
      color:$Colortextviolet
    }

    .progress-bar-fill {
      background-color: #afa517; /* couleur verte */
      height: 100%;
      border-radius: 5px;
      transition: width 0.3s ease-in-out;
      
    }
    
  }

  .progress-text {
    text-align: center;
    margin-top: 10px;
    font-size: 1.1em;
    
  }
}

.admin-banque-kamas h2,
.admin-banque-kamas h3 {
  color: $Colortextviolet;

  
}


.formbanque {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
  
  
  
}


form label {
  font-weight: bold;
  color: $Colortextviolet;
  
}

form input[type="number"],
form input[type="text"],
form input[type="file"],
form select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 150px;
  
}

form button[type="submit"] {
  padding: 10px;
  background-color: $Colortextred;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 50%;
  margin: auto;
 
}

form button[type="submit"]:hover {
  background-color: #00796b;
}

.transactions-list {
  list-style: none;
  padding: 0;
}

.transaction-item {
  background-color:transparent;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  width: 80%;
  margin: auto;
}

.transaction-item p {
  margin: 5px 0;
  color: $Colortextwithe;
  text-align: left;
  
}

.transaction-item strong {
  color: $Colortextviolet;
  
}

.transaction-item img {
  margin-top: 10px;
  border-radius: 4px;
  max-width: 100px;
}
.transactions-columns {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .transactions-column {
    width: 48%;  /* Pour que chaque colonne occupe environ la moitié de la largeur */
    padding: 15px;
    background-color: transparent;

    h4 {
      color: $Colortextviolet;
      margin-bottom: 10px;
    }

    .transactions-list {
      list-style: none;
      padding: 0;
    }

    .transaction-item {
      background-color: transparent;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      padding: 10px;
      margin-bottom: 15px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    }
  }
}

}