@use './../colors.scss' as *;

.tableau-de-bord {

  width: 80%;
  margin: auto;

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  .form-notes {
 width: 300px;
 text-align: center;
 margin: auto;
    // Espacement entre les éléments du formulaire
    .form-group {
    display: flex;
    flex-direction: column;  // Change à column pour une disposition verticale
 align-content: center;
 justify-content: center;

 

      input, textarea {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        width: 100%;  // Fait en sorte que l'input/textarea prenne toute la largeur disponible
      }
    }

    button {
      padding: 10px;
      background-color: $Colortextred;
      color: $Colortextwithe;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      width: 100%;
      margin: 0;
      align-self: flex-end;  // Aligne le bouton à droite
    }

    button:hover {
      background-color: $Colortextgrey;
    }
  }

  .notes {
    margin: auto;
    width: 70%;
  
    .mois-notes {
      margin-bottom: 30px;
  
      h3 {
        font-size: 1.5em;
        margin-bottom: 10px;
        color: $Colortextviolet;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
  
        &.open span {
          transform: rotate(180deg); // Flèche vers le bas
          transition: transform 0.3s ease;
        }
  
        &.closed span {
          transform: rotate(0deg); // Flèche vers la droite
          transition: transform 0.3s ease;
        }
      }
  
      .notes-list {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
  
        ul {
          list-style: none;
          padding-left: 0;
        }
  
        .note-item {
          padding: 10px;
          border: 1px solid #ddd;
          margin-bottom: 10px;
          border-radius: 5px;
          color: $Colortextwithe;
          text-align: start;
  
          strong {
            color: $Colortextviolet;
          }
  
          p {
            margin: 5px 0;
          }
        }
      }
  
      .notes-list.open {
        max-height: 600px; /* Ajustez cette valeur selon vos besoins */
        overflow-y: auto; /* Ajoute une barre de défilement verticale */
        padding-right: 10px; /* Pour un meilleur espacement avec la barre */
      }
  
      .notes-list.closed {
        max-height: 0;
      }
    }
  }
  
  
  }

