@use './colors.scss' as*;
@media screen and (max-width: 1024px){
   
        
        .Nav-gauche{
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            padding-top: 1em;
            
            .Logo{
                width: 175px;
            }
            .nav-desktop{
                img{
                    width: 25px;
                }
            }
        }
        .Nav-mid{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center; 
            width: 100%;
            margin-top: 1em;
            p{
                color:$Colortextred;
                font-family: $Fontbold;                
            }
            a{
                text-decoration: none;
                font-size: 13.5px;
            }
            .ligne{
               display: none;
            }
            .line{
                display: none;
            }
            .nav-item{
                .points{
                    display: none;
                    img{
                        width: 30px;
                    }
                }
            }
            
         
           
            
        }
        
        /* Cachez le point blanc par défaut */
        .point-blanc {
            display: none;
            
        }
        
        /* Lorsque le NavLink est actif */
        .active p {
            color: white;
            
        }
        
        .active .point-rouge {
            display: none;
            
        }
        
        .active .point-blanc {
            display: inline;
        }

        .Nav-droite{
            display: none;
        }
      
    }
    @media screen and (min-width: 1024px){
        nav{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
         
            .nav-desktop{
                 display: none;   

                }
        }
        .Nav-gauche{

            .Logo{
                width: 
                225px;
                margin-left: 2em;
                
            }
            
            padding-top: 1em;
            
        }
        .Nav-mid{
            
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 1em;
            
            
           
            
            p{
                color:$Colortextred;
                font-family: $Fontbold;
            }
            a{
                text-decoration: none;
            }
            .line{
                margin-bottom: -2.5em;
                img{
                    width: 60%;
                }
            }
            .nav-item{
                .points{
                    img{
                        width: 35px;
                    }
                }
            }
      
            .jeux-list {
                display: flex;
                flex-direction: column;
                background: transparent;
                border: 1px solid #ccc;
                position: absolute;
                margin-top: 10px;
                z-index: 10;
              
                .jeux-item {
                  padding: 10px;
                  color: #333;
                  text-decoration: none;
              
                  &:hover {
                    scale: 1.05;
                  }
                  img{
                        width: 3;
                  }
                }
              }
              
            
        }
        .ligne{
            display: none;
        }
        /* Cachez le point blanc par défaut */
        .point-blanc {
            display: none;
        }
        
        /* Lorsque le NavLink est actif */
        .active p {
            color: white;
        }
        
        .active .point-rouge {
            display: none;
        }
        
        .active .point-blanc {
            display: inline;
        } 
        .Nav-droite{
            display: flex;
            justify-content: space-between;
            align-items: center;
           img{
            margin: 1em;
            height: 57px;
            width: 80%;
            margin-left: 0;
           }
           button{
            background: none;
            text-decoration: none;
            border: none;
           }
           }
          
        }
    

  