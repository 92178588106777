@use './colors.scss' as*;

@media (max-width: 768px){
  .error-message {
    color: $Colortextred;
    padding: 10px;
    margin: 10px 0;
    text-align: center;
    font-family: $Fontbold;
    border-radius: 5px;
  }
  .video-player {
    
    .video-gallery {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .video-carousel {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-bottom: 20px;
        color:$Colortextviolet;
    .carousel-controls{
        color:$Colortextred;
    
    }
        .video-item {
          iframe{
            width: 95%;
            height: 100%;
            
          }

          text-align: center;
          h3{
            
            margin: auto;
            margin-bottom: 0.5em;
          }
        }
  
        .carousel-controls {
          background-color: transparent;
          display: none;
          padding: 10px;
          border: none;
          cursor: pointer;
          font-size: 24px;
          &:disabled {
            opacity: 0.5;
          }
        }
      }
  
      .next-videos-preview {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        margin-top: 8em;
        h4{
            color:$Colortextviolet;
        }
        ul {
         
          list-style: none;
          padding: 0;
          width: 100%;
  
          li {
            
           
            color:$Colortextwithe;
          
            margin: auto;
            width: 95%;
            img {
              width: 95%;
              height: auto;
              
              
            }
            p {
              font-size: 12px;
            }
          }
        }
      }
      .end-message{
        a{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color:$Colortextwithe;
        font-family: $Fontbold;
      }
        img{
          width: 30%;
        }
      }
    }
  }
}
@media (min-width: 768px){
  .error-message {
    color: $Colortextred;
    padding: 10px;
    margin: 10px 0;
    text-align: center;
    font-family: $Fontbold;
    border-radius: 5px;
  }
  .video-player {
   
    .video-gallery {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .video-carousel {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-bottom: 20px;
        color:$Colortextviolet;
    .carousel-controls{
        color:$Colortextred;
    
    }
        .video-item {
          width: 560px;
          text-align: center;
          h3{
            
            margin: auto;
            margin-bottom: 0.5em;
          }
        }
  
        .carousel-controls {
          background-color: transparent;
          
          padding: 10px;
          border: none;
          cursor: pointer;
          font-size: 24px;
          &:disabled {
            opacity: 0.5;
          }
        }
      }
  
      .next-videos-preview {
        display: flex;
        justify-content: center;
        gap: 10px;
        h4{
            color:$Colortextviolet;
        }
        ul {
          display: flex;
          list-style: none;
          padding: 0;
          width: 100%;
  
          li {
            text-align: center;
            padding: 1em;
            color:$Colortextwithe;
            width: 100%;
            height: auto;

            p {
              font-size: 12px;
              margin-top: 5px;
            }
          }
        }
      }
      .end-message{
        a{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color:$Colortextwithe;
        font-family: $Fontbold;
      }
        img{
          width: 30%;
        }
      }
    }
  }
}


  