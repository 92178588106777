@use './colors.scss' as*;

@media (max-width: 768px){
  .p-screenshot {
    color: $Colortextred;
    padding: 10px;
    margin: 10px 0;
    text-align: center;
    font-family: $Fontbold;
    border-radius: 5px;
  }
  h1{
    color:$Colortextwithe;
}
.screenshot-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
    width: 100%;
  }
  
  .screenshot-card {
    width:80%; // Ajustez la largeur selon vos besoins
    margin: 10px;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .screenshot-card:hover {
    transform: scale(1.02); // Agrandir légèrement la carte au survol
  }
  
  .screenshot-card-image {
    width: 100%;
    border-radius: 10px;
  }
  
  .screenshot-card-info {
    padding: 10px;
    color:$Colortextwithe;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .carousel-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); // Fond semi-transparent
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; // Assurez-vous que l'overlay soit au-dessus
  }
  
  .close-carousel {
    position: fixed;
    top:15%;
    right:44%;
  z-index: 1000;
    border: none;
  
    cursor: pointer;
  }
}
@media (min-width: 768px){
  .p-screenshot {
    color: $Colortextred;
    padding: 10px;
    margin: 10px 0;
    text-align: center;
    font-family: $Fontbold;
    border-radius: 5px;
  }
  h1{
    color:$Colortextwithe;
}
.screenshot-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
    width: 100%;
  }
  
  .screenshot-card {
    width:32%; // Ajustez la largeur selon vos besoins
    margin: 10px;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .screenshot-card:hover {
    transform: scale(1.02); // Agrandir légèrement la carte au survol
  }
  
  .screenshot-card-image {
    width: 100%;
    border-radius: 10px;
    
  }
  
  .screenshot-card-info {
    padding: 10px;
    color:$Colortextwithe;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .carousel-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); // Fond semi-transparent
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; // Assurez-vous que l'overlay soit au-dessus
  }
  
  .close-carousel {
    position: fixed;
    top: 2%;
    right: 47%;
  z-index: 1000;
    border: none;
  
    cursor: pointer;
  }
}