// Background color
$Background-color : #0b132b;

// Color 
$Colortextred:#D31C1C;
$Colortextviolet: #6E7DAB;
$Colortextgrey:#8B8982;
$Colortextwithe:#FFFFFF;
$ColortextBlack:#000000;

// Font spécifique

$Fontbold: "the_bold_fontbold";
// accordeon accueil 
$accordion-width: 100%; // Largeur maximale de l'accordéon


