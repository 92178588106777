@use '../colors.scss' as*;
.admin-articles {
    
    min-height: 100vh ;
    h2 {
      font-size: 24px;

      color: $Colortextviolet;
      img{
        width: 80%;
      }
    }
    label{
    color: $Colortextwithe}


   
    ul {
      list-style-type: none;
      margin: auto;
      padding: 0;
      margin-top: 2em;
      
      li {
        border: 1px solid #D31C1C;
        border-radius: 8px;
        padding: 15px;
        transition: box-shadow 0.3s;
        width: 50%;
        margin: auto;
        margin-bottom: 2em;
      
        
       
  
        h3 {
          margin: 0;
          font-size: 20px;
          color: $Colortextviolet;
        }
  
        p {
          margin: 5px 0;
          color: $Colortextwithe;
          overflow: hidden;
          
        }
  

  
        button {
          margin-right: 10px;
          padding: 8px 12px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s;
  
          &:hover {
            background-color: $Colortextviolet;
            color: white;
          }
  
          &.save {
            background-color: #28a745;
            color: white;
          }
  
          &.cancel {
            background-color: #dc3545;
            color: white;
          }
        }
        img{
          width: 80%;
        }
        div {
          margin-bottom: 10px;
  
          input, textarea {
            width: 100%;
            padding: 10px;
            margin-bottom: 10px;
            border: 1px solid #ddd;
            border-radius: 4px;
            box-sizing: border-box;
            margin-top: 9em;
          }
  
          textarea {
            height: 80px;
            resize: none;
          }
        }
      }
    }
    .avantouverture{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: auto ;

      width: 90%;
      img{
        margin-bottom: 2em;
        width: 90%;
      }
    }
  }
  