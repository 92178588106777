/* Login.scss */
@use './colors.scss' as*;
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  
    h2 {
      font-size: 2rem;
      margin-bottom: 1.5rem;
      color: $Colortextred;
    }
  
    form {
      display: flex;
      flex-direction: column;
      
      width: 50%;
      max-width: 300px;
      padding: 2rem;
      background-color: $Colortextviolet;
      border-radius: 8px;
       
        .label{
            display: flex;
            justify-content: space-around;
            align-items: center;
        }
  
      input {
        padding: 0.8rem;
        width: 90%;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1rem;
        margin-bottom: 1em;
  
        &:focus {
          border-color: #333;
          outline: none;
        }
      }
  
      button {
        padding: 0.8rem;
        font-size: 1rem;
        color: #fff;
        background-color: $Background-color;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: $Colortextred;
        }
      }
  
      .error {
        color: #e63946;
        font-size: 0.9rem;
        margin-top: 1rem;
        text-align: center;
      }
    }
  }
  