@use '../colors.scss'as*;

@media (max-width: 768px){
  .steams-nav {
    width: 100%;
  
  
    p {
      color: $Colortextwithe;
      font-family: $Fontbold;
      a {
        text-decoration: none;
        color: $Colortextviolet;
      }
    }
  }
  .header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
  }
  .header-logo {
    display: flex;
    gap: 20px;
    margin: auto;
    margin-bottom: 1.5em;
    margin-top: 1em;
  }
  
  .streams-container {
  margin: auto;
    /* Espacememarnt entre le lecteur Twitch et le chat */
    width: 100%;
  }
  .live-section{
  
    display:flex;
    flex-direction: column;
    justify-content: center;
    .iframe1{
      width: 98%;
      height: 450px !important;
    }
    .iframe2{
      width: 75%;
      height: 450px !important;
      margin: auto;
    }
  }
  
  .live-logo {
    width: 100px;
    height: 100px;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
  }
  
  .live-logo.active {
    box-shadow: 0px 0px 15px red;
  }
  
  .live-details {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Espacement entre les différentes parties */
  }
  
  .twitch-chat {
    margin-top: 20px;
  }
  
  .description {
    margin-top: 20px;
    font-size: 1.1rem;
    color: $Colortextwithe;
  }
  
  
  
  .link-images {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  
    margin: auto;
    margin-bottom: 1em;
    width: 85%;
    img{
      width: 90%;
    }
    
  
  }
  
}


@media (min-width: 768px){
  .steams-nav {
    width: 100%;
  
  
    p {
      color: $Colortextwithe;
      font-family: $Fontbold;
      a {
        text-decoration: none;
        color: $Colortextviolet;
      }
    }
  }
  .header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
  }
  .header-logo {
    display: flex;
    gap: 20px;
    margin: auto;
    margin-bottom: 1.5em;
    margin-top: 1em;
  }
  
  .streams-container {
  margin: auto;
    /* Espacememarnt entre le lecteur Twitch et le chat */
    width: 100%;
  }
  .live-section{
  
    display:flex;
   
   
    justify-content: center;
  }
  
  .live-logo {
    width: 100px;
    height: 100px;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
  }
  
  .live-logo.active {
    box-shadow: 0px 0px 15px red;
  }
  
  .live-details {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Espacement entre les différentes parties */
  }
  
  .twitch-chat {
    margin-top: 20px;
  }
  
  .description {
    margin-top: 20px;
    font-size: 1.1rem;
    color: $Colortextwithe;
  }
  
  
  
  .link-images {
    display: flex;
    justify-content: space-around;
    margin: auto;
    margin-bottom: 1em;
    width: 85%;
    img{
      width: 100%;
    }
    
  
  }
  
}
