@use '../style/colors.scss' as*;


@media (max-width: 768px){
    .All-footer{
        background-color: transparent transparent;
        width: 90%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        margin: auto;
        margin-bottom: 0.25em;
       
        
        .droits{
            
            font-size: 12px;
            color: $Colortextwithe;
            margin-bottom: 0.2em;
        }
        .liens{
            margin-bottom: 2em;
            font-family: $Fontbold;
            a{
                text-decoration: none;
                color: $Colortextviolet;
            }
        }
    
    }
}




@media (min-width: 768px){
    .All-footer{
    background-color: transparent transparent;
    display: block;
    padding: 0.2em;
    bottom: 0%;
    margin-bottom: 0.25em;
    display: flex;
    justify-content: space-between;
   
    
    .droits{
        
        font-size: 14px;
        color: $Colortextwithe;
    }
    .liens{
        width: 20%;
        font-family: $Fontbold;
        a{
            text-decoration: none;
            color: $Colortextviolet;
        }
    }

}}