@use '../colors.scss' as*;
.admin-screenshot {
 
  min-height: 100vh;
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: $Colortextviolet;
  }
  label{
  color: $Colortextwithe}
 .apercu-screen{
  img{
    width: 90%;
    display: flex;
    margin: 1em auto;
  }

 }
  ul {
    list-style-type: none;
    padding: 2em;
    margin-bottom: 0;

    li {
      border: 1px solid $Colortextred;
      border-radius: 8px;
      padding: 15px;
      transition: box-shadow 0.3s;
      width: 50%;
      margin: auto;
      margin-bottom: 2em;
      img{
        width: 500px;
        display: flex;
        
        
        margin: 1em auto;
      }
     

      h3 {
        margin: 0;
        font-size: 20px;
        color: $Colortextviolet;
      }

      p {
        margin: 5px 0;
        color: $Colortextwithe;
      }



      button {
        margin-right: 10px;
        padding: 8px 12px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: $Colortextviolet;
          color: white;
        }

        &.save {
          background-color: #28a745;
          color: white;
        }

        &.cancel {
          background-color: #dc3545;
          color: white;
        }
      }

      div {
        margin-bottom: 10px;

        input, textarea {
          width: 100%;
          padding: 10px;
          margin-bottom: 10px;
          border: 1px solid #ddd;
          border-radius: 4px;
          box-sizing: border-box;
        }

        textarea {
          height: 80px;
          resize: none;
        }
      }
    }
  }
  }
  