@use '../../../colors.scss' as*;
@media (max-width: 768px){
  .mount-card {
    border: 1px solid $Colortextred; // Bordure gris clair
    border-radius: 10px; // Coins arrondis
    padding: 0.2em; // Espacement interne
    margin: 16px; // Espacement externe
    transition: transform 0.2s; // Transition pour l'effet de survol
    width: 30%;
    // Effet de survol
    &:hover {
      transform: translateY(-5px); // Légère élévation lors du survol
    }
   h3{
    border-top:none ;
   }
    h5 {
      font-size: 1rem; // Taille du texte pour le titre
      color: $Colortextviolet; // Couleur du texte
      margin-bottom: 12px; // Espacement sous le titre
      margin-top: 0;
      
    }
  
    .mount-image {
      max-width: 100%; // L'image prend 100% de la largeur de la carte
      border-radius: 10px; // Coins légèrement arrondis pour l'image
    }
  
    p {
      font-size: 0.8rem; // Taille de la police pour le texte
      color: $Colortextviolet; // Couleur du texte
      margin: 4px 0; // Espacement vertical entre les paragraphes
    }
  
    .characteristics {
      border-top: 1px solid $Colortextred; // Ligne supérieure pour séparer les caractéristiques
      padding-top: 12px; // Espacement interne en haut
      margin-top: 12px; // Espacement externe en haut
      color: $Colortextviolet; // Couleur du texte

    }
  }
  .alert{
    p{
    color:green
    
}}
}
@media (min-width: 768px){
  .mount-card {
    border: 1px solid $Colortextred; // Bordure gris clair
    border-radius: 10px; // Coins arrondis
    padding: 0.2em; // Espacement interne
    margin: 16px; // Espacement externe
    transition: transform 0.2s; // Transition pour l'effet de survol
    width: 7%;
    // Effet de survol
    &:hover {
      transform: translateY(-5px); // Légère élévation lors du survol
    }
   h3{
    border-top:none ;
   }
    h5 {
      font-size: 1rem; // Taille du texte pour le titre
      color: $Colortextviolet; // Couleur du texte
      margin-bottom: 12px; // Espacement sous le titre
      margin-top: 0;
      
    }
  
    .mount-image {
      max-width: 100%; // L'image prend 100% de la largeur de la carte
      border-radius: 10px; // Coins légèrement arrondis pour l'image
    }
  
    p {
      font-size: 0.8rem; // Taille de la police pour le texte
      color: $Colortextviolet; // Couleur du texte
      margin: 4px 0; // Espacement vertical entre les paragraphes
    }
  
    .characteristics {
      border-top: 1px solid $Colortextred; // Ligne supérieure pour séparer les caractéristiques
      padding-top: 12px; // Espacement interne en haut
      margin-top: 12px; // Espacement externe en haut
      color: $Colortextviolet; // Couleur du texte

    }
  }
  .alert{
    p{
    color:green
    
}}
}