.home-page{
  
    .image-dofus{
      display: flex;
  flex-direction: column;
  justify-content: space-between;
      img{
        width: 300px;
        margin: 0em;
      }
    }
}