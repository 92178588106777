@use './style/colors.scss' as*;


@media screen and (max-width: 768px) {
.App {
  text-align: center;
  background-image: url(./images/Nav/Fond-mobile.png);
  width: 100%;
  min-height: 100vh;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}





}
@media screen and (min-width: 768px) {
  .App {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    background-image: url(./images/Nav/Fond-desktop.png);
    background-color: $Background-color;
    width: 100%;
    min-height: 100vh;
    padding-bottom: 0;
  
  .footer{
    width: 100%;
    position: relative;
    bottom:0%;
  }
  }
  
  
  
  
  
  }