@use './colors.scss' as*;

.apropos-container {
    max-width: 80%; // Limite la largeur du texte
    margin: 0 auto; // Centre le contenu
    margin-top: 4em; // Ajoute de l'espace autour du texte
     // Couleur de fond douce
    
    
  }
  
  .apropos-title {
    font-size: 2em; // Taille de la police pour le titre
    color: $Colortextviolet; // Couleur du texte
    text-align: center; // Centre le titre
    margin-bottom: 20px; // Espace sous le titre
  }
  
  .apropos-text {
    font-size: 1.25em; // Taille de la police pour le texte
    line-height: 1.6; // Hauteur de ligne pour une meilleure lisibilité
    color: $Colortextviolet; // Couleur du texte
    margin-bottom: 15px; // Espace entre les paragraphes
    
    strong {
      color: $Colortextwithe; // Couleur des éléments en gras
    }
  }
  .toutliens{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-bottom: 1em;
    a{
     
      img{
        width: 100px;
      }
    }
  }

      

  


    .apropos-title-liens{
      margin: 2em;
      

    }
  