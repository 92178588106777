@use '../colors.scss' as*;

@media (max-width: 768px){
  .admin-liens {
  
    padding: 20px;
    background-color: transparent;
  
  
   width: 80%;
    margin: 0 auto;
  }
  
  h2 {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 20px;
    color:$Colortextviolet;
  }
  
  .liens-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    
  }
  
  .lien-item {
    text-decoration: none;
    color: $Colortextwithe;
    font-size: 1.3rem;
    padding: 8px;
    border: 1px solid $Colortextred;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
    margin: 0.2em;
  }
  
  .lien-item:hover {
    background-color: $Colortextviolet;
    color: white;
  }
  
}
@media (min-width: 768px){
  .admin-liens {
  
    padding: 20px;
    background-color: transparent;
  
  
   width: 30%;
    margin: 0 auto;
  }
  
  h2 {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 20px;
    color:$Colortextviolet;
  }
  
  .liens-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    
  }
  
  .lien-item {
    text-decoration: none;
    color: $Colortextwithe;
    font-size: 1.3rem;
    padding: 8px;
    border: 1px solid $Colortextred;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
    margin: 0.2em;
  }
  
  .lien-item:hover {
    background-color: $Colortextviolet;
    color: white;
  }
  
}