@use './colors.scss' as*;
@media (max-width: 768px){
  .article-detail {
    
    border-radius: 10px;
    font-size: 12px;
  
    h1 {
      color: $Colortextwithe;
      
    }
  
    img {
      width: 90%;
      

    }
  
    p,li{
      
      width: 100%;
      margin: auto;
      background-color: rgba(255, 255, 255, 0.0); // 0.8 pour 80% d'opacité
      color:$Colortextwithe;
      font-weight: bold;
      
      font-size: 12px;
   
    }
    .card-desc{
      width: 85%;
      margin: auto;
      text-align: left;
      background-color: $Background-color;
      border: 1px solid $Colortextviolet;
      border-bottom: none;
      padding: 0.6em;
      img {
        display: block;
        margin: 0 auto;
        margin-top: 1em;
      }
      
  
     
   
      a{
        text-decoration: none;
        color:$Colortextred;
      }
    }
    .card-infos{
     
     
      
      width: 85%;
      margin: auto;
      padding: 0.6em;
      border: 1px solid $Colortextviolet;
      background-color: $Background-color;
    
      
      p{
        width: 100%;
        color:$Colortextwithe;
        
        
        

      }
     
    }
    .close{
      rotate: 180deg;
     
        }
    
}
}
@media (min-width: 768px){
  .article-detail {
    
    
    border-radius: 10px;
  
    h1 {
      color: $Colortextwithe;
      
    }
  
    img {
      width: 61%;
      
    }
  
    p,li{
      
      width: 100%;
      margin: auto;
      background-color: rgba(255, 255, 255, 0.0); // 0.8 pour 80% d'opacité
      color:$Colortextwithe;
      font-weight: bold;
      
     
   
    }
    .card-desc{
      width: 60%;
      margin: auto;
      text-align: left;
      background-color: $Background-color;
      border: 1px solid $Colortextviolet;
      border-bottom: none;
      padding: 0.6em;

       img {
        display: block;
        margin: 0 auto;
        margin-top: 1em;
      }
      
    
  
      
   
      a{
        text-decoration: none;
        color:$Colortextred;
      }
    }
    .card-infos{
     
     
      
      width: 60%;
      margin: auto;
    
      padding: 0.6em;
      border: 1px solid $Colortextviolet;
      background-color: $Background-color;
    
      
      p{
        width: 100%;
        color:$Colortextwithe;
        
        

      }
     
    }
    .close{
      rotate: 180deg;
     
        }
    
}
}

  

