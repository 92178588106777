@use './colors.scss' as*;

@media (max-width: 768px){
  .nav-tertiary {
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: $Fontbold;
    .Nav-gauche,
    .Nav-droite {
      display: flex;
      align-items: center;
    }
  
    .Nav-contenu {
      display: flex;
      align-items: center;
    
      margin: auto;
      margin-top: 1em;
      .separator-icon{
        display: none;
      }
    }
  
    .nav-item {
      margin: 0 1rem;
      text-decoration: none;
      
    }
  
    .selected-game {
      
      color: red;
      font-size: 12px;
    }
  
    .unselected-game {
      color: white;
      font-size: 12px;
    }
  
    .nav-arrow {
      background: none;
      border: none;
      font-size: 1rem;
      cursor: pointer;
      color: #ff0000;
      margin-bottom: 0;
    }
  }

}


@media (min-width: 768px){
.nav-tertiary {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: $Fontbold;
    .Nav-gauche,
    .Nav-droite {
      display: flex;
      align-items: center;
    }
  
    .Nav-contenu {
      display: flex;
      align-items: center;
      margin: auto;
      margin-top: 2em;
    }
  
    .nav-item {
      margin: 0 1rem;
      text-decoration: none;
    }
  
    .selected-game {
      color: red;
    }
  
    .unselected-game {
      color: white;
    }
    .separator-icon{
      width: 15px;
      
     }
    .nav-arrow {
      background: none;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
      color: #ff0000;
      margin-bottom: 0;
    }
  }
}