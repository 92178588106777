@use '../../../colors.scss' as*;

@media (max-width: 768px){
  .mount-management {
    padding: 20px; // Espacement interne général
    
    border-radius: 8px; // Coins arrondis
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Ombre portée
  
    .filters {
      margin-bottom: 20px; // Espacement en bas
      select, input {
        padding: 8px; // Espacement interne
        border: 1px solid #ced4da; // Bordure
        border-radius: 4px; // Coins arrondis
      }
    }
    .bloc-creation{
        display: flex;
       
        justify-content: space-around;
        align-items: center;
        
        
      }
      .titre{
        color:$Colortextwithe
      }
      h3,h2{
        color:$Colortextwithe;
        
      }
      h3{
        border-top: none;
        color:$Colortextviolet;
      }
    .new-mount-form, .new-enclosure-form, .transfer-mount {
        
      border-radius: 8px; // Coins arrondis
      margin-bottom: 20px; // Espacement en bas
     
     
      margin: auto;
     
      .titre-panel{
        h2{
        color:$ColortextBlack;
    }
      }
      h2 {
        margin-bottom: 10px; // Espacement en bas
      }
    
  
      input, select {
        display: block; // Placer chaque élément sur une nouvelle ligne
        width: 250px; // Prendre toute la largeur
        margin-bottom: 10px; // Espacement en bas
        padding: 10px; // Espacement interne
        border: 1px solid #ced4da; // Bordure
        border-radius: 4px; // Coins arrondis
      }
  
      button {
        background-color: $Colortextviolet; // Couleur du bouton
        color: white; // Couleur du texte
        border: none; // Pas de bordure
        border-radius: 4px; // Coins arrondis
        padding: 10px; // Espacement interne
        cursor: pointer; // Curseur pointeur
  
        &:hover {
          background-color: $Colortextgrey; // Couleur au survol
        }
      }
    }
    .mounts{
      display: flex;
     
      flex-wrap: wrap;
      justify-content: center;      
    }
    .cadre{
     width: 95%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: start;
      justify-content: center;
      color: $Colortextviolet;
      
      .cadre-droite{
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

      }
    }
    .mounts-list {
     
        
      margin-top: 20px; // Espacement en haut
      .enclosure {
        margin-bottom: 20px; // Espacement en bas
        
        h3 {
          margin-bottom: 10px; // Espacement en bas
          color: #495057; // Couleur du texte
        }
  
     
      }
    }
  

  }

}
@media (min-width: 768px){
  .mount-management {
    padding: 20px; // Espacement interne général
    
    border-radius: 8px; // Coins arrondis
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Ombre portée
  
    .filters {
      margin-bottom: 20px; // Espacement en bas
      select, input {
        padding: 8px; // Espacement interne
        border: 1px solid #ced4da; // Bordure
        border-radius: 4px; // Coins arrondis
      }
    }
    .bloc-creation{
        display: flex;
        justify-content: space-around;
        align-items: center;
        
        
      }
      .titre{
        color:$Colortextwithe
      }
      h3,h2{
        color:$Colortextwithe;
        
      }
      h3{
        border-top: none;
        color:$Colortextviolet;
      }
    .new-mount-form, .new-enclosure-form, .transfer-mount {
        
      padding: 16px; // Espacement interne
      border-radius: 8px; // Coins arrondis
      margin-bottom: 20px; // Espacement en bas
     
     
      margin: auto;
     
      .titre-panel{
        h2{
        color:$ColortextBlack;
    }
      }
      h2 {
        margin-bottom: 10px; // Espacement en bas
      }
    
  
      input, select {
        display: block; // Placer chaque élément sur une nouvelle ligne
        width: 300px; // Prendre toute la largeur
        margin-bottom: 10px; // Espacement en bas
        padding: 10px; // Espacement interne
        border: 1px solid #ced4da; // Bordure
        border-radius: 4px; // Coins arrondis
      }
  
      button {
        background-color: $Colortextviolet; // Couleur du bouton
        color: white; // Couleur du texte
        border: none; // Pas de bordure
        border-radius: 4px; // Coins arrondis
        padding: 10px; // Espacement interne
        cursor: pointer; // Curseur pointeur
  
        &:hover {
          background-color: $Colortextgrey; // Couleur au survol
        }
      }
    }
    .mounts{
      display: flex;
     
      flex-wrap: wrap;
      justify-content: center;      
    }
    .cadre{
     
      display: flex;
      flex-direction: row;
      align-items: start;
      justify-content: center;
      color: $Colortextviolet;
      
      .cadre-droite{
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

      }
    }
    .mounts-list {
     
        
      margin-top: 20px; // Espacement en haut
      .enclosure {
        margin-bottom: 20px; // Espacement en bas
        
        h3 {
          margin-bottom: 10px; // Espacement en bas
          color: #495057; // Couleur du texte
        }
  
     
      }
    }
  

  }

}