.all-error{
   
    .logotop{
        width: 25%;
        margin: auto;
    }
    img{
        
        margin-top: 2em;
       }
}

.troisbloc{
   
    height: 100vh;
    margin: auto;


   img{
    margin-top: 8em;
   }
}