@use '../colors.scss' as*;

@media (max-width: 768px){
  .admin-nav {
    
    height: 100%;
    width: 100%;
    margin: auto;
   
    .admin-nav-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    
    }
  
    .admin-card {
      background-color: #f4f4f4;
      padding: 20px;
      text-align: center;
      cursor: pointer;
      border-radius: 10px;
      transition: transform 0.3s ease;
      width: 25%;
      margin: 1em;
      margin-top: 5em;
      h3{
        font-size: 16px;
      }
   
  
      .admin-card-image {
        width: 100%;
        
        object-fit: cover;
        border-radius: 5px;
        margin-bottom: 10px;
      }
    }
  }
}



@media (min-width: 768px){
.admin-nav {
    
    height: 100%;
    width: 100%;
    margin: auto;
   
    .admin-nav-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    
    }
  
    .admin-card {
      background-color: #f4f4f4;
      padding: 20px;
      text-align: center;
      cursor: pointer;
      border-radius: 10px;
      transition: transform 0.3s ease;
      width: 20%;
      
      margin: 1em;
      margin-top: 5em;
      
      &:hover {
        transform: scale(1.05);
      }
  
      .admin-card-image {
        width: 30%;
        
        object-fit: cover;
        border-radius: 5px;
        margin-bottom: 10px;
      }
    }
  }
}