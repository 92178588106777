@use './colors.scss' as*;

@media (max-width: 768px){
    .actu {
        h1 {
            color: $Colortextwithe;
            font-weight: $Fontbold;
            
           
        }
        .all-card {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap; // Permet aux cartes de s'aligner sur plusieurs lignes si nécessaire
            margin-bottom: 0;
        }
        .card {
            background-color: $Colortextwithe;
            width: 90%;
            border-radius: 10px;
            margin-bottom: 2em;
            margin-right: 2em;
            overflow: hidden; // Assurez-vous que le contenu débordant soit masqué
            transition: 0.2s ease-out;
            &:hover {
                scale: 1.05;
            }
            h2{
                font-size: 0.8em;
                border-bottom: 1px solid $Colortextred;
                padding-bottom: 0.5em;
                margin-top: 0.5em;
                font-family: $Fontbold;
                margin-bottom: 0;
    
            }
          
            img {
                width: 100%;
                
                
            }
            .content {
                .desc {
                    width: 85%;
                    margin: auto;
                    white-space: nowrap; // Empêche le retour à la ligne
                    overflow: hidden; // Masque le texte débordant
                    text-overflow: ellipsis; // Ajoute des "..." si le texte est trop long
                    max-height: 0em; // Ajuste cette valeur selon ta taille de police
                
                }
                .card-infos {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    bottom: 0%;
                    margin: 0.5em;
                    border-top: 1px dotted $Colortextred;
                    font-family: $Fontbold;
                    font-size: 0.6em;
                    .tag {
                        a {
                            text-decoration: none;
                            color: $Colortextgrey;
                        }
                    }
                }
                p {
                    margin-top: 0.2em;
                    margin-bottom: 0.2em;
                    
                }
               
            }
        }
    }
    

}

@media (min-width: 768px){
    .actu {
        h1 {
            color: $Colortextwithe;
            
            
           
        }
        .all-card {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap; // Permet aux cartes de s'aligner sur plusieurs lignes si nécessaire
            margin-bottom: 0;
        }
        .card {
            background-color: $Colortextwithe;
            width: 23%;
            border-radius: 10px;
            margin-bottom: 2em;
            margin-right: 2em;
            overflow: hidden; // Assurez-vous que le contenu débordant soit masqué
            transition: 0.2s ease-out;
            &:hover {
                scale: 1.05;
            }
            h2{
                font-size: 1.2em;
                border-bottom: 1px solid $Colortextred;
                padding-bottom: 0.5em;
                margin-top: 0.5em;
                
    
            }
          
            img {
                width: 100%;
                
                
            }
            .content {
                .desc {
                    width: 85%;
                    margin: auto;
                    white-space: nowrap; // Empêche le retour à la ligne
                    overflow: hidden; // Masque le texte débordant
                    text-overflow: ellipsis; // Ajoute des "..." si le texte est trop long
                    max-height: 0em; // Ajuste cette valeur selon ta taille de police
                
                }
                .card-infos {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    bottom: 0%;
                    margin: 0.5em;
                    border-top: 1px dotted $Colortextred;
                    
                    .tag {
                        a {
                            text-decoration: none;
                            color: $Colortextgrey;
                            
                        }
                    }
                }
                p {
                    margin-top: 0.2em;
                    margin-bottom: 0.2em;
                    
                }
               
            }
        }
    }
    
    
}



