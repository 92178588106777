/* Styles pour le conteneur principal */
@use '../../colors.scss' as*;
@media (max-width: 768px){
  .table-container-archimonstre{
  
 
    margin: auto;
   width: 90%;
    border: 1px solid $Colortextgrey;
    border-radius: 8px;
    background-color: $Colortextwithe;
    margin-top: 3em;  
    /* Styles pour le tableau */
    table {
      
      border-collapse: collapse;
      margin: 20px 0;
      font-size: 1em;
      text-align: left;
      background-color: $Colortextwithe;
      border-radius: 8px;



    }
h1 {
    text-align: center;
    color: $Background-color;
  }

  
  thead {
    background-color: $Background-color;
    color: white;
    
  }
  
  th, td {
    border-bottom: 1px solid $Colortextgrey;
    font-size: 0.6em;
  }
  
  /* Styles pour le survol des lignes */
  tbody tr:hover {
    background-color: #f1f1f1;
   
  }
  /* Styles pour les champs de saisie */
  input[type="text"],
  input[type="number"],
  select {
    padding: 8px;
    margin: 10px 0;
    border: 1px solid $Colortextgrey;
    border-radius: 4px;
 

  }
  input[type="number"]{
    padding: 0;
    text-align: center;
    border: 1px solid $Colortextgrey;
    border-radius: 4px;
  }
  
  /* Styles pour les cases à cocher */
  input[type="checkbox"] {
    transform: scale(1);
 
  }
  
  /* Styles pour le conteneur du tableau */
  
  .progress-bar {
    width: 100%;
    background-color:$Colortextgrey;
    border-radius: 5px;
    overflow: hidden;
    margin: 20px 0;
    
  }
  
  .progress-fill {
    height: 20px;
    background-color: $Colortextred; // Couleur de la barre de progression
    transition: width 0.3s;
  }
  .all-colonnes{
    width: 100%;
    
    th{
        text-align: center;
    }
   
  }}
}
@media (min-width: 768px){.table-container-archimonstre{
  max-width: 70%;
  margin: auto;
  padding: 20px;
  border: 1px solid $Colortextgrey;
  border-radius: 8px;
  background-color: $Colortextwithe;
  margin-top: 3em;
h1 {
  text-align: center;
  color: $Background-color;
}

/* Styles pour le tableau */
table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1em;
  text-align: left;
  background-color: $Colortextwithe;
  border-radius: 8px;
  overflow: hidden;

  
}

thead {
  background-color: $Background-color;
  color: white;
}

th, td {
  padding: 5px;
  border-bottom: 1px solid $Colortextgrey;
}

/* Styles pour le survol des lignes */
tbody tr:hover {
  background-color: #f1f1f1;
}


/* Styles pour les champs de saisie */
input[type="text"],
input[type="number"],
select {
  padding: 8px;
  margin: 10px 0;
  border: 1px solid $Colortextgrey;
  border-radius: 4px;
  width: calc(100% - 20px);
}

/* Styles pour les cases à cocher */
input[type="checkbox"] {
  transform: scale(1.5);
  
}

/* Styles pour le conteneur du tableau */

.progress-bar {
  width: 100%;
  background-color:$Colortextgrey;
  border-radius: 5px;
  overflow: hidden;
  margin: 20px 0;
}

.progress-fill {
  height: 20px;
  background-color: $Colortextred; // Couleur de la barre de progression
  transition: width 0.3s;
}
.all-colonnes{
  width: 100%;
  
  th{
      text-align: center;
  }
 
}}}