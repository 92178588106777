@use '../style/colors.scss' as*;
@media (max-width: 768px){
    .lesite{
        width: 90%;
        margin: auto;
        margin-top: 4em;
        margin-bottom: 2em;
        
        h1,h2{
            color:$Colortextviolet;
          
        }
        ul{
            text-decoration: none;
            padding-left: 0;
        }
        p,li{
            font-size: 1em;
            line-height: 1.6;
            color: $Colortextviolet;
            margin-bottom: 15px;
            list-style-type: none;
            strong{
                color:$Colortextwithe;
                
            }
            a{
                text-decoration: none;
                color:$Colortextgrey;
                border-bottom: 2px solid $Colortextwithe;
            }
        }
    }
}
@media (min-width: 768px){
    .lesite{
        width: 80%;
        margin: auto;
        margin-top: 4em;
        margin-bottom: 2em;
        text-align: justify;
        h1,h2{
            color:$Colortextviolet;
            text-align: left;
        }
        ul{
            text-decoration: none;
        }
        p,li{
            font-size: 1.25em;
            line-height: 1.6;
            color: $Colortextviolet;
            margin-bottom: 15px;
            
            strong{
                color:$Colortextwithe;
                
            }
            a{
                text-decoration: none;
                color:$Colortextgrey;
                border-bottom: 2px solid $Colortextwithe;
            }
        }
    }
}
