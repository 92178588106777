@use '../colors.scss' as*;

@media (max-width: 768px){
  .admin-planing-video {
    
    background-color: transparent;

    width: 90%;
    margin: 0 auto;
    h2, h3{
        text-align: center;
        font-size: 1.5rem;
        margin-bottom: 20px;
        color:$Colortextviolet;
      }

     
}
.calendar-edit{
  p{
  color:$Colortextwithe;
  font-family: $Fontbold;
}
a{
  
    color:$Colortextviolet;
    font-family: $Fontbold;
    
  
}}
}
@media (min-width: 768px){
  .admin-planing-video {
  padding: 20px;
  background-color: transparent;

  width: 90%;
  margin: 0 auto;
  h2, h3{
      text-align: center;
      font-size: 1.5rem;
      margin-bottom: 20px;
      color:$Colortextviolet;
    }
  
    
   }  
   .calendar-edit{
    p{
    color:$Colortextwithe;
    font-family: $Fontbold;
  }
  a{
    
      color:$Colortextviolet;
      font-family: $Fontbold;
      
    
  }
  }}