@use './colors.scss' as*;

@media (max-width: 768px){
  .jeux-container {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: center;
    padding: 2rem;
  }
  
  .game-card {
    background: linear-gradient(145deg, transparent);
    border-radius: 12px;
    padding: 1rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    
  
    &:hover {
      background: linear-gradient(145deg, transparent);
      transform: translateY(-10px);
  
      h3 {
        color: $Colortextviolet;
      }
    }
  
    .game-image {
      width: 100%;
      height: auto;
      border-radius: 10px;
      margin-bottom: 1rem;
    
      transition: filter 0.4s ease;
  
      &:hover {
        filter: grayscale(0);
      }
    }
  
    h3 {
      font-size: 1.1rem;
      color: #ffffff;
      text-align: center;
      transition: color 0.4s ease;
    }
  }
  
}
@media (min-width: 768px){
  .jeux-container {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: center;
    padding: 4rem;
    width: 80%;
    margin: auto;
  }
  
  .game-card {
    background: linear-gradient(145deg, transparent);
    border-radius: 12px;
    padding: 1rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.4s ease;
  
    &:hover {
      background: linear-gradient(145deg, transparent);
      transform: translateY(-10px);
  
      h3 {
        color: $Colortextviolet;
      }
    }
  
    .game-image {
      width: 100%;
      height: auto;
      border-radius: 10px;
      margin-bottom: 1rem;
      filter: grayscale(100%);
      transition: filter 0.4s ease;
  
      &:hover {
        filter: grayscale(0);
      }
    }
  
    h3 {
      font-size: 1.1rem;
      color: #ffffff;
      text-align: center;
      transition: color 0.4s ease;
    }
  }
  
  
  
}

