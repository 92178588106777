.live-logo {
    position: relative;
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
    transition: border-color 0.3s;
    text-align: center;
  }
  
  .live-logo img {
    width: 100px; /* Ajuste la taille du logo */
    height: 100px;
    object-fit: cover;
  }
  
  .live-logo .live-status {
    font-weight: bold;
    margin-top: 8px;
    color: #fff;
  }
  
  .live-logo.live {
    border: 4px solid red; /* Contour rouge pour les lives en cours */
  }
  
  .live-logo.offline {
    border: 4px solid white; /* Contour blanc pour les lives hors ligne */
    color: gray;
  }
  
  .live-logo.live .live-status {
    color: red; /* Texte rouge pour "Live On" */
  }
  
  .live-logo.offline .live-status {
    color: white; /* Texte blanc pour "Live Off" */
  }
  