body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: 'the_bold_fontbold';
  src: url('./style/font/the_bold_font_-_free_version_-_2023-webfont.woff2') format('woff2'),
       url('./style/font/the_bold_font_-_free_version_-_2023-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;

}

