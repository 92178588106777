@use '../colors.scss' as*;
/* Home.scss */
@media (max-width: 768px){
  .home-container {
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
  
    h1 {
      font-size: 2.5rem;
      text-align: center;
      color: $Colortextwithe;
      margin-bottom: 2rem;
    }
  
    h2 {
      font-size: 1.8rem;
      color:$Colortextwithe;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  
    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      background: #f9f9f9;
      padding: 1.5rem;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      margin-bottom: 2rem;
  
      input[type="text"],
      input[type="file"] {
        padding: 0.8rem;
        font-size: 1rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        transition: border-color 0.3s;
  
        &:focus {
          border-color: #666;
          outline: none;
        }
      }
  
      button {
        padding: 0.8rem;
        font-size: 1rem;
        color: #fff;
        background-color: #333;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;
       
        &:hover {
          background-color: #555;
        }
      }
  
      .error {
        color: #e63946;
        font-size: 0.9rem;
        text-align: center;
        margin-top: 1rem;
      }
    }
    .success {
      color: green;
      font-weight: bold;
      margin-top: 20px;
    }
  
    .error {
      color: red;
      font-weight: bold;
      margin-top: 20px;
    }
    
  }
}
@media (min-width: 768px){
  .home-container {
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
  
    h1 {
      font-size: 2.5rem;
      text-align: center;
      color: $Colortextwithe;
      margin-bottom: 2rem;
    }
  
    h2 {
      font-size: 1.8rem;
      color:$Colortextwithe;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  
    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      background: #f9f9f9;
      padding: 1.5rem;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      margin-bottom: 2rem;
  
      input[type="text"],
      input[type="file"] {
        padding: 0.8rem;
        font-size: 1rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        transition: border-color 0.3s;
  
        &:focus {
          border-color: #666;
          outline: none;
        }
      }
  
      button {
        padding: 0.8rem;
        font-size: 1rem;
        color: #fff;
        background-color: #333;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: #555;
        }
      }
  
      .error {
        color: #e63946;
        font-size: 0.9rem;
        text-align: center;
        margin-top: 1rem;
      }
    }
    .success {
      color: green;
      font-weight: bold;
      margin-top: 20px;
    }
    
    .error {
      color: red;
      font-weight: bold;
      margin-top: 20px;
    }
    
  }
}

  