@use '../style/colors.scss' as*;



@media (max-width: 768px){
  .layout {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(135deg, #3d3d555a, #3b3b5e27 );
    overflow: hidden;
    position: relative;
    
  }
  .hide-nav-footer {
    /* Cacher la nav et le footer seulement pour ce composant */
    nav,
    .All-footer {
      display: none;
    }
  }
  .logo-container {
    position: relative;
    animation: float 4s ease-in-out infinite;
    width: 80%;
  }
  
  .logo {
    width: 100%;
    transform-origin: center;
    animation: spin 10s linear infinite, depth 4s ease-in-out infinite;
  }
  .cta-button {
    background-color: $Colortextred;  /* Couleur d'arrière-plan du bouton */
    color: $Colortextwithe;               /* Texte blanc */
    font-size: 1.2rem;
    font-family: $Fontbold;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: $Colortextviolet;  /* Couleur d'arrière-plan au survol */
  }
  
  .cta-button:active {
    transform: scale(0.95); /* Animation de réduction au clic */
  }
  
  /* Animation pour un mouvement flottant */
  @keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-50px);
    }
  }
  
  /* Animation de rotation */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Animation pour donner une profondeur */
  @keyframes depth {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
  
  .content-layout  {
    margin-top: 30px;
    text-align: center;
    color: $Colortextwithe;
    font-family: $Fontbold;
  
    h1 {
      font-size: 1.5rem;
      text-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
    }
  
    p {
      font-size: 1rem;
      margin-top: 10px;
      line-height: 2em;
      opacity: 0.8;
      text-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
    }
  }
  
  /* Arrière-plan animé pour plus de profondeur */

  

}
@media (min-width: 768px){

  .layout {
 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(135deg, #3d3d555a, #3b3b5e27 );
    overflow: hidden;
    position: relative;
     nav,
    .All-footer {
      display: none;
    }

  }
 
  .logo-container {
    position: relative;
    animation: float 4s ease-in-out infinite;
    width: 40%;
  }
  
  .logo {
    width: 100%;
    transform-origin: center;
    animation: spin 10s linear infinite, depth 4s ease-in-out infinite;
  }
  .cta-button {
    background-color: $Colortextred;  /* Couleur d'arrière-plan du bouton */
    color: $Colortextwithe;               /* Texte blanc */
    font-size: 1.2rem;
    font-family: $Fontbold;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: $Colortextviolet;  /* Couleur d'arrière-plan au survol */
  }
  
  .cta-button:active {
    transform: scale(0.95); /* Animation de réduction au clic */
  }
  
  /* Animation pour un mouvement flottant */
  @keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-50px);
    }
  }
  
  /* Animation de rotation */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Animation pour donner une profondeur */
  @keyframes depth {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
  
  .content-layout {
    margin-top: 30px;
    text-align: center;
    color: $Colortextwithe;
    font-family: $Fontbold;
  
    h1 {
      font-size: 2.5rem;
      text-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
    }
  
    p {
      font-size: 1.2rem;
      margin-top: 10px;
      opacity: 0.8;
      text-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
    }
  }
  

  

}
